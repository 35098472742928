import React, {useRef, useEffect, useState} from "react";
import "./LanguageChange.css"

const LanguageChange = () => {
    const [language, setLanguage] = useState("kz")

    useEffect(() => {
        // Fetch language
        const language = localStorage.getItem("language")
        if (language) {
            setLanguage(language)
        }
    }, [])

    const changeLanguage = (language) => {
        setLanguage(language)
        localStorage.setItem("language", language)
        window.location.reload()
    }

    return (
        <div className={"Language__container"}>
            <div className={"Language__item"} onClick={() => changeLanguage("kz")}>
                <img src="./img/kazakhstan.svg" alt="kazakhstan" className={"Language__flag"}/>
                <div className={"Language__name"}>
                    Қазақша
                </div>
            </div>
            
            <div className={"Language__item"} onClick={() => changeLanguage("ru")}>
                <img src="./img/russia.svg" alt="russia" className={"Language__flag"}/>
                <div className={"Language__name"}>
                    Русский
                </div>
            </div>
            
            <div className={"Language__item"} onClick={() => changeLanguage("en")}>
                <img src="./img/uk.svg" alt="uk" className={"Language__flag"}/>
                <div className={"Language__name"}>
                    English
                </div>
            </div>
        </div>
    )
}

export default LanguageChange;